import { Alert, Box, Divider, Link, Stack, Typography } from '@mui/material';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import Button from '~components/Button/Button';
import FormProvider, { RHFTextField } from '~components/hook-form';
import { PATH_AUTH } from '~config/routes/paths';
import { setPreviousAddons } from '~screens/order/store/orderSlice';
import { api } from '~services/index';
import loginSchema from '../schema/login.schema';
import { useLoginMutation } from '../services/authApi';
import { setSubscriberUserInfo } from '../store/authSlice';
import SocialButtons from '~components/social-buttons/SocialButtons';
type FormValuesProps = {
  email: string;
  password: string;
  afterSubmit?: string;
};
function AuthLoginForm({
  onLoginSuccess,
  onResetPassword
}: any) {
  const {
    push
  } = useRouter();
  const dispatch = useDispatch();
  const [login, {
    isLoading
  }] = useLoginMutation();
  const methods = useForm<FormValuesProps>({
    resolver: loginSchema
  });
  const {
    handleSubmit,
    formState: {
      errors
    }
  } = methods;
  const {
    user
  } = useSelector((state: any) => state.auth);
  const order_all_submits = useSelector((state: any) => state.order);
  const {
    step1FormData: {
      _id: orderId1
    },
    step2FormData: {
      _id: orderId2
    },
    step3FormData: {
      orderId: orderId3
    },
    step4FormData: {
      orderId: orderId4
    }
  } = order_all_submits || {};
  const orderId = orderId4 || orderId3 || orderId2 || orderId1 || undefined;
  const handleResetPassword = () => {
    if (onResetPassword) onResetPassword();else push(PATH_AUTH.resetPassword);
  };
  const handleLogin = handleSubmit(async data => {
    let userId = user?.userDto?._id;
    const response = await login({
      ...data,
      userId: userId || undefined,
      orderId: orderId || undefined,
      role: 'Client'
    });
    const {
      error,
      data: respData
    }: any = response || {};
    if (error) {
      toast.error(error?.data?.message);
      if (error?.data?.userId) {
        push(`/verify-email/?userId=${error?.data?.userId}`);
      }
    }
    if (respData) {
      dispatch(setSubscriberUserInfo(null));
      if (respData?.previousAddons) dispatch(setPreviousAddons(true));
      dispatch(api.util.resetApiState());
      if (onLoginSuccess) onLoginSuccess();else push('/');
    }
  });
  return <FormProvider methods={methods} data-sentry-element="FormProvider" data-sentry-component="AuthLoginForm" data-sentry-source-file="AuthLoginForm.tsx">
      <Box sx={{
      pt: '40px',
      width: '100%'
    }} data-sentry-element="Box" data-sentry-source-file="AuthLoginForm.tsx">
        <SocialButtons data-sentry-element="SocialButtons" data-sentry-source-file="AuthLoginForm.tsx" />
      </Box>
      <Divider sx={{
      my: '22px'
    }} data-sentry-element="Divider" data-sentry-source-file="AuthLoginForm.tsx"><Typography sx={{
        fontSize: '12px',
        fontWeight: 400
      }} data-sentry-element="Typography" data-sentry-source-file="AuthLoginForm.tsx">OR Log In with email and password</Typography></Divider>

      <Stack spacing={3} data-sentry-element="Stack" data-sentry-source-file="AuthLoginForm.tsx">
        {!!errors.afterSubmit && <Alert severity="error">
            {errors.afterSubmit.message}
          </Alert>}
        <RHFTextField name="email" label="Email address" data-sentry-element="RHFTextField" data-sentry-source-file="AuthLoginForm.tsx" />
        <RHFTextField name="password" label="Password" data-sentry-element="RHFTextField" data-sentry-source-file="AuthLoginForm.tsx" />
      </Stack>


      <Stack alignItems="flex-end" sx={{
      my: 2
    }} data-sentry-element="Stack" data-sentry-source-file="AuthLoginForm.tsx">
        <Box onClick={handleResetPassword} color="inherit" sx={{
        textDecoration: 'underline',
        cursor: 'pointer'
      }} data-sentry-element="Box" data-sentry-source-file="AuthLoginForm.tsx">
          Forgot password?
        </Box>
      </Stack>
      <Button title='Login' loading={isLoading} onClick={handleLogin} data-sentry-element="Button" data-sentry-source-file="AuthLoginForm.tsx" />

    </FormProvider>;
}
export default AuthLoginForm;