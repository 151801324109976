import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from '~components/Button/Button';
import FormProvider, { RHFTextField } from '~components/hook-form';
import useResponsive from '~hooks/useResponsive';
import resetPasswordSchema from '../schema/reset-password.schema';
import { useForgotPasswordMutation } from '../services/authApi';
type FormValuesProps = {
  email: string;
};
function AuthResetPasswordForm({
  onSuccess
}: any) {
  const {
    push
  } = useRouter();
  const isMobile = useResponsive('down', 'md');
  const [forgotPassword, {
    isLoading
  }] = useForgotPasswordMutation();
  const methods = useForm<FormValuesProps>({
    resolver: resetPasswordSchema
  });
  const {
    handleSubmit
  } = methods;
  const handleResetPassword = handleSubmit(async data => {
    const response = await forgotPassword(data?.email);
    const {
      error,
      data: respData
    }: any = response || {};
    if (error) toast.error(error?.data?.message);
    if (respData) {
      toast.success(respData?.message);
      if (onSuccess) onSuccess(data?.email, respData?.id);else push(`/new-password/?email=${data?.email}&userId=${respData?.id}`);
    }
  });
  return <FormProvider methods={methods} data-sentry-element="FormProvider" data-sentry-component="AuthResetPasswordForm" data-sentry-source-file="AuthResetPasswordForm.tsx">
      <RHFTextField name="email" label="Email address" sx={{
      width: !isMobile ? '450px' : undefined
    }} data-sentry-element="RHFTextField" data-sentry-source-file="AuthResetPasswordForm.tsx" />
      <Button loading={isLoading} title='Reset Password' onClick={handleResetPassword} sx={{
      mt: 3,
      width: !isMobile ? '450px' : undefined
    }} data-sentry-element="Button" data-sentry-source-file="AuthResetPasswordForm.tsx" />
    </FormProvider>;
}
export default AuthResetPasswordForm;