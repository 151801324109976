import { Dialog, Grid } from '@mui/material';
import Image from '~components/image/Image';
import useResponsive from '~hooks/useResponsive';
type SigninModalType = {
  open: boolean;
  setOpen: any;
  leftImage: string;
  children: any;
  forbidClose?: boolean;
};
function AuthModalLayout({
  open,
  setOpen,
  leftImage,
  children,
  forbidClose
}: SigninModalType) {
  const isMd = useResponsive('down', 'md');
  const onClose = () => {
    if (!forbidClose) setOpen(false);
  };
  if (!open) return null;
  return <Dialog fullScreen={isMd} fullWidth={true} maxWidth={"md"} open={open} sx={{
    border: '0px solid red'
  }} onClose={onClose} data-sentry-element="Dialog" data-sentry-component="AuthModalLayout" data-sentry-source-file="AuthModalLayout.tsx">
            <Grid container sx={{
      display: 'flex',
      border: '0px solid red'
    }} data-sentry-element="Grid" data-sentry-source-file="AuthModalLayout.tsx">
                <Grid sx={{
        display: {
          xs: 'none',
          md: 'block'
        },
        border: '0px solid red'
      }} item md={5} xs={12} margin={0} padding={0} data-sentry-element="Grid" data-sentry-source-file="AuthModalLayout.tsx">
                    <Image disabledEffect visibleByDefault alt="auth" src={leftImage} imageStyles={{
          objectFit: 'cover'
        }} sx={{
          border: '0px solid red',
          borderRadius: '0px',
          margin: '0',
          padding: '0',
          height: '100%',
          width: '350px'
        }} data-sentry-element="Image" data-sentry-source-file="AuthModalLayout.tsx" />
                </Grid>
                <Grid item md={7} xs={12} padding={3} data-sentry-element="Grid" data-sentry-source-file="AuthModalLayout.tsx">
                    {children}
                </Grid>
            </Grid>
        </Dialog>;
}
export default AuthModalLayout;