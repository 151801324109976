import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useRouter } from 'next/router';
import useResponsive from '~hooks/useResponsive';
import AuthLoginForm from './components/AuthLoginForm';
import SocialButtons from '~components/social-buttons';
function Login({
  onRegister,
  ...rest
}: any) {
  const {
    palette: {
      text
    }
  }: any = useTheme();
  const {
    push
  } = useRouter();
  const isMobile = useResponsive('down', 'md');
  const handleRegister = () => {
    if (onRegister) onRegister();else push('./signup');
  };
  return <>
      <Stack spacing={2} sx={{
      mb: '22px',
      border: '0px solid red',
      alignItems: isMobile ? 'center' : undefined
    }} data-sentry-element="Stack" data-sentry-source-file="Login.tsx">
        <Typography variant={isMobile ? "h3" : "h4"} data-sentry-element="Typography" data-sentry-source-file="Login.tsx">
          Log in to your account
        </Typography>
        <Typography onClick={handleRegister} sx={{
        cursor: 'pointer',
        display: 'flex',
        border: '0px solid red',
        maxWidth: '95%',
        width: '25em'
      }} data-sentry-element="Typography" data-sentry-source-file="Login.tsx">
          New user?

          {' '}
          <Typography component='span' sx={{
          pl: '1em',
          textDecoration: 'underline'
        }} data-sentry-element="Typography" data-sentry-source-file="Login.tsx">
            Register
          </Typography>

        </Typography>


      </Stack>
      <AuthLoginForm {...rest} data-sentry-element="AuthLoginForm" data-sentry-source-file="Login.tsx" />
    </>;
}
export default Login;